import { fotmatOptions } from '@lib/helper'
import { concat } from 'lodash'

export const LEAVE_TYPE = [
  'business',
  'leave_without_pay',
  'sick',
  'vacation',
  'extra_vacation',
  'working_from_home',
  'working_from_office',
  'working_remotely',
]
export const LEAVE_PERIOD = ['all_day', 'first_half', 'second_half']
export const LEAVE_STATUS = ['rejected', 'pending', 'approved']

export const LEAVE_TYPE_OPTIONS = concat(
  [
    {
      label: 'All Leave Types',
      value: undefined,
    },
  ],
  fotmatOptions(LEAVE_TYPE),
)

export const LEAVE_PERIOD_OPTIONS = fotmatOptions(LEAVE_PERIOD)

export const LEAVE_STATUS_OPTIONS = concat(
  [
    {
      label: 'All Status',
      value: undefined,
    },
  ],
  fotmatOptions(LEAVE_STATUS),
)
export const EMPLOYEE_ROLE = ['user', 'admin', 'manager']
export const EMPLOYEE_ROLE_OPTIONS = fotmatOptions(EMPLOYEE_ROLE)
export const SEARCH_EMPLOYEE_ROLE_OPTIONS = concat(
  [
    {
      label: 'All Roles',
      value: undefined,
    },
  ],
  fotmatOptions(EMPLOYEE_ROLE),
)
export const EMPLOYEE_STATUS = ['active', 'inactive']
export const EMPLOYEE_STATUS_OPTIONS = fotmatOptions(EMPLOYEE_STATUS)
