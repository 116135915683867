import { showToast } from '@components'
import { axios, getErrorMessage } from '@lib/helper'

import { bulkReviewLeavesPath, leavesPath, mutualLeavePath, reviewLeavesPath } from '@path'
import { CreateLeaveParam, LeaveParam, leaveResponse } from '@types'
import { AxiosError } from 'axios'

const formatParam = (payload: CreateLeaveParam) => {
  return {
    ...payload,
    reason: payload.reason === '' ? undefined : payload.reason,
  }
}
export const leavesServices = () => {
  return {
    createLeave: async (payload: CreateLeaveParam) => {
      try {
        const res = await axios.post(leavesPath, formatParam(payload))
        showToast({ type: 'success', message: 'Create request successfully' })
        return res.data || {}
      } catch (err) {
        const errorMessage = getErrorMessage(err as AxiosError)
        showToast({ type: 'error', message: errorMessage })
        return false
      }
    },
    updateLeave: async (payload: CreateLeaveParam & { id: number }) => {
      try {
        const res = await axios.put(`${leavesPath}/${payload.id}`, {
          leaves: payload,
        })
        showToast({ type: 'success', message: 'Update leave successfully' })
        return res.data || {}
      } catch (err) {
        const errorMessage = getErrorMessage(err as AxiosError)
        showToast({ type: 'error', message: errorMessage })
        return false
      }
    },
    getLeaves: async (payload?: LeaveParam): Promise<leaveResponse> => {
      const res = await axios.get(leavesPath, { params: payload })
      return res.data || {}
    },
    deleteLeave: async (id: number) => {
      try {
        const res = await axios.delete(`${leavesPath}/${id}`, null)
        showToast({ type: 'success', message: 'Delete leave successfully' })
      } catch (err) {
        const errorMessage = getErrorMessage(err as AxiosError)
        showToast({ type: 'error', message: errorMessage })
      }
    },

    approveLeave: async (id: number) => {
      try {
        const res = await axios.patch(reviewLeavesPath(id), {
          leaves: {
            status: 'approved',
          },
        })
        showToast({ type: 'success', message: res.data.message })
      } catch (err: unknown) {
        const errorMessage = getErrorMessage(err as AxiosError)
        showToast({ type: 'error', message: errorMessage })
      }
    },
    approveBulkLeave: async (ids: number[]) => {
      try {
        const res = await axios.patch(bulkReviewLeavesPath, {
          leaves: {
            leave_ids: ids,
            status: 'approved',
          },
        })
        showToast({ type: 'success', message: res.data.message })
      } catch (err: unknown) {
        const errorMessage = getErrorMessage(err as AxiosError)
        showToast({ type: 'error', message: errorMessage })
      }
    },
    rejectLeave: async (id: number, reason: string) => {
      try {
        const res = await axios.patch(reviewLeavesPath(id), {
          leaves: {
            status: 'rejected',
            rejection_reason: reason,
          },
        })
        showToast({ type: 'success', message: res.data.message })
      } catch (err) {
        const errorMessage = getErrorMessage(err as AxiosError)

        showToast({ type: 'error', message: errorMessage })
      }
    },
    rejectBulkLeave: async (ids: number[], reason: string) => {
      try {
        const res = await axios.patch(bulkReviewLeavesPath, {
          leaves: {
            leave_ids: ids,
            status: 'rejected',
            rejection_reason: reason,
          },
        })
        showToast({ type: 'success', message: res.data.message })
      } catch (err) {
        const errorMessage = getErrorMessage(err as AxiosError)

        showToast({ type: 'error', message: errorMessage })
      }
    },
    mutualLeave: async (start_date: String, end_date: String, department_id: Number) => {
      try {
        const res = await axios.post(mutualLeavePath, {
          leave: {
            start_date: start_date,
            end_date: end_date,
            department_id: department_id,
          },
        });
        return res.data || {}
      } catch (err) {
        const errorMessage = getErrorMessage(err as AxiosError)
        showToast({ type: 'error', message: errorMessage })
      }
    }
  }
}
